import { useEffect, useState, useContext } from 'react';
import imgDescuento from '../../../../../styles/images/istockphoto-1145066470-612x612-removebg-preview.png';
import consultarApi from '../../../../../helpers/consultarApi';
import { TokenContext } from '../../../../../context/TokenContext';
const Descuento = ({
  showCodigo,
  setShowCodigo,
  setCodigo,
  codigo,
  setDescuento,
  descuento,
  setTotalDolares,
  totalDolares,
  setTotalBs,
  plan,
  setCodigoAplicado,
  setMostrarErrores,
  setNavbarErrores,
  estaAplicado,
  setEstaAplicado,
}) => {
  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);
  useEffect(() => {
    if (descuento != null && descuento != undefined) {
      setCodigo(descuento);
      setCodigoAplicado(true);
      const descuentoAplicado = totalDolares - descuento[0]?.mt_ds;
      setTotalDolares(descuentoAplicado);
      setTotalBs(descuentoAplicado * (plan.ta_fa / 100));
      localStorage.setItem(
        'RCV_DIGITAL_USDESCUENTO',
        JSON.stringify(descuento[0]?.id_us)
      );

      localStorage.setItem(
        'RCV_DIGITAL_DESCUENTO',
        JSON.stringify({
          nb_us: descuento[0]?.nb_us,
          mt_ba: descuento[0].mt_ba,
          mt_ds: descuento[0].mt_ds,
        })
      );
      setEstaAplicado('agente');
      localStorage.removeItem('RCV_DIGITAL_CODIGO_DESCUENTO');
    }
  }, [descuento]);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    let codigoDescuentoLink = localStorage.getItem(
      'RCV_DIGITAL_CODIGO_DESCUENTO'
    );

    if (codigoDescuentoLink) {
      const codigoParseado = JSON.parse(codigoDescuentoLink);
      let urlDescuento = `v1/getAgent?p1=${codigoParseado.codigoDescuento}&p2=&p3=&p4=&op=1&token=`;

      consultarApi(
        urlDescuento,
        0,
        setDescuento,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(() => localStorage.setItem('RCV_DIGITAL_DESCONTADO', true));
    }
  }, [descuento]);

  const aplicarDescuento = async () => {
    let urlDescuento = `v1/getAgent?p1=${codigo}&p2=&p3=&p4=&op=1&token=`;

    const respuesta = await consultarApi(
      urlDescuento,
      0,
      setDescuento,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );

    if (respuesta) {
      setNavbarErrores([respuesta.errorData.message]);
      setMostrarErrores(true);
    }
  };

  return (
    <div className="descuentoYPromo__descuento-opcion">
      <div
        onClick={() => {
          setShowCodigo(true);
          let inputElement = document.getElementById('miInput');
          inputElement.select();
        }}
        className={`descuentoYPromo__descuento-opcion-pregunta ${
          showCodigo ? 'ocultar' : ''
        } ${estaAplicado === 'cupon' ? 'desactivado' : ''}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <p>¿Tienes c&oacute;digo de descuento por agente?</p>
        <span>Haz click aqui</span>
        <img
          loading="lazy"
          src={imgDescuento}
          alt="imagen de codigo descuento, mano con dos tickets"
          className={`descuentoYPromo__descuento-opcion-pregunta-img ${
            hover ? 'hovered' : ''
          }`}
        />
      </div>
      <div
        className={`descuentoYPromo__descuento-opcion-input  ${
          showCodigo ? 'mostrar' : ''
        }`}
      >
        <div className="descuentoInputContainer">
          <label>C&oacute;digo</label>
          <input
            type="text"
            onChange={e => setCodigo(e.target.value)}
            id="miInput"
            autoComplete="off"
            className="descuentoYPromo__descuento-opcion-input-inputext"
          ></input>
        </div>
        <div className="descuentoYPromo__descuento-opcion-input-botones">
          <button
            onClick={() => setShowCodigo(false)}
            className="descuentoYPromo__descuento-opcion-input-botones-cancelar"
          >
            Cancelar
          </button>
          <button
            onClick={() => aplicarDescuento()}
            className="descuentoYPromo__descuento-opcion-input-botones-aplicar"
          >
            Aplicar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Descuento;
