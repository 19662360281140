import React, { useState, useEffect } from 'react';
import DescuentoAplicado from './Pago/Checkout/DescuentoAplicado';
import Descuento from './Pago/Checkout/Descuento';
import useProcesoProvider from '../../../hooks/useProcesoProvider';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import CuponDes from './Cupon/CuponDes';
import NavbarErrors from '../../NavbarLite/NavbarErrors';

const DescuentosYPromos = () => {
  const context = useProcesoProvider();
  const {
    token,
    login,
    setTokenController,
    setTokenExp,
    setToken,
    handlePrev,
    cambiarPaso,
  } = context;
  const [showCodigo, setShowCodigo] = useState(false);
  const navigate = useNavigate();

  const [descuento, setDescuento] = useState(null);
  let plan = JSON.parse(localStorage.getItem('RCV_DIGITAL_PLAN'));
  let productos = JSON.parse(localStorage.getItem('RCV_DIGITAL_CARRITO'));
  let total = 0;
  let descuentoJSON = JSON.parse(localStorage.getItem('RCV_DIGITAL_DESCUENTO'));
  const [codigo, setCodigo] = useState('');
  const [aplicar, setAplicar] = useState(false);
  const [navbarErrores, setNavbarErrores] = useState([]);
  const [mostrarErrores, setMostrarErrores] = useState(false);
  const [codigoAplicado, setCodigoAplicado] = useState(false);
  const [estaAplicado, setEstaAplicado] = useState('');
  productos?.forEach(producto => {
    total += producto.precio * producto.cantidad;
  });
  const [totalDolares, setTotalDolares] = useState(total + plan.mt_pr);
  const [totalBs, setTotalBs] = useState(totalDolares * (plan.ta_fa / 100));
  let montoRedondeado = totalBs.toFixed(2);
  let montoFormateado = parseFloat(montoRedondeado).toLocaleString('es-VE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  useEffect(() => {
    if (descuentoJSON != undefined || descuentoJSON != null) {
      setCodigoAplicado(true);
      setTotalDolares(descuentoJSON.ds_dl);
      montoFormateado = descuentoJSON.ds_bs;
      setEstaAplicado('agente');
    }
  }, []);

  useEffect(() => {
    cambiarPaso(6, 4);
  }, []);

  useEffect(() => {
    if (codigo.length > 0) {
      setAplicar(true);
    } else {
      setAplicar(false);
    }
  }, [codigo]);
  const handleSubmit = e => {
    setTimeout(() => {
      navigate('/proceso/resumen');
    }, 300);
  };

  return (
    <div className="descuentoYPromo">
      <NavbarErrors
        navbarErrores={navbarErrores}
        setMostrarErrores={setMostrarErrores}
        mostrarErrores={mostrarErrores}
      />
      <div className="descuentoYPromo__header">
        <h2>
          Aplica tu <span>descuento</span>.
        </h2>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="button-tooltip-2">Cancelar proceso</Tooltip>}
        >
          <Link to={'/'}>
            <IoClose />
          </Link>
        </OverlayTrigger>
      </div>
      <div className="descuentoContenedor">
        <div className="descuentoYPromo__descuento">
          {codigoAplicado ? (
            <DescuentoAplicado
              descuentoJSON={descuentoJSON}
              setTotalBs={setTotalBs}
              setTotalDolares={setTotalDolares}
              setCodigoAplicado={setCodigoAplicado}
              setDescuento={setDescuento}
              setShowCodigo={setShowCodigo}
              descuento={descuento}
              setEstaAplicado={setEstaAplicado}
            />
          ) : (
            <Descuento
              estaAplicado={estaAplicado}
              setEstaAplicado={setEstaAplicado}
              showCodigo={showCodigo}
              setShowCodigo={setShowCodigo}
              setCodigo={setCodigo}
              aplicar={aplicar}
              token={token}
              login={login}
              setTokenController={setTokenController}
              setTokenExp={setTokenExp}
              setToken={setToken}
              codigo={codigo}
              setDescuento={setDescuento}
              descuento={descuento}
              totalDolares={totalDolares}
              setTotalDolares={setTotalDolares}
              setTotalBs={setTotalBs}
              plan={plan}
              setCodigoAplicado={setCodigoAplicado}
              montoFormateado={montoFormateado}
              setMostrarErrores={setMostrarErrores}
              setNavbarErrores={setNavbarErrores}
            />
          )}
        </div>
        <CuponDes
          setMostrarErrores={setMostrarErrores}
          setNavbarErrores={setNavbarErrores}
          estaAplicado={estaAplicado}
          setEstaAplicado={setEstaAplicado}
        />
      </div>

      <div className="descuentoYPromo__footer">
        <div></div>
        <div className="descuentoYPromo__footer-contenedor">
          <button
            className="descuentoYPromo__footer-volver"
            onClick={() => {
              const sinProducto = localStorage.getItem('RCV_DIGITAL_S/P');
              sinProducto
                ? handlePrev('/proceso/vehiculo')
                : handlePrev('/proceso/producto');
            }}
          >
            ANTERIOR
          </button>
          <button
            onClick={() => handleSubmit()}
            className="descuentoYPromo__footer-siguiente"
          >
            SIGUIENTE
          </button>
        </div>
      </div>
    </div>
  );
};

export default DescuentosYPromos;
