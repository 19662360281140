import React, { useState, useContext, useEffect } from 'react';
import logoVr from '../../../img/logo_n_ulimate.png';
import { TokenContext } from '../../../context/TokenContext';
import validateUser from '../../Login/Helpers/validateUser';
import { FaLock } from 'react-icons/fa';
import { BsPersonFill } from 'react-icons/bs';
import { LoginContext } from '../../../context/LoginContext';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
const IniciarHero = ({ setNavbarErrores, setMostrarErrores }) => {
  const { setToken } = useContext(TokenContext);
  const [verified, setVerified] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState('');
  const [showContra, setShowContra] = useState(false);
  const navigate = useNavigate();
  const { setLog } = useContext(LoginContext);
  const style = {
    fontWeight: 700,
    top: '-16px',
    left: '0',
    fontSize: '0.8em',
    backgroundColor: 'transparent',
    color: 'black',
  };

  const [datosForm, setDatosForm] = useState({
    usuario: {
      us_us: '',
      pw_us: '',
    },
  });

  const datosDelFormulario = e => {
    const { name, value } = e.target;

    setDatosForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const loguearse = async e => {
    setLoading(true);
    e.preventDefault();
    let respuesta = await validateUser(datosForm, setToken, setLoading);
    setVerified(respuesta);
  };

  useEffect(() => {
    if (verified?.response === true) {
      setLoading(false);
      setLog(true);
      navigate('/inicio');
    } else if (verified?.status === false) {
      setLoading(false);
      setMostrarErrores(true);
      setNavbarErrores([verified.message]);
    }
  }, [verified]);

  return (
    <div className="heroindex__inicio">
      <div className="heroindex__inicio-header">
        {' '}
        <img loading="lazy" alt="logo de Venezuela Responsable" src={logoVr} />
        <span>
          {' '}
          Iniciar <span>sesi&oacute;n.</span>
        </span>
      </div>

      <form onSubmit={loguearse} id="loginForm">
        <div className="iniciarSesion__container-form-usuario">
          <label
            htmlFor="us_us"
            className={datosForm.us_us ? 'focused' : ''}
            style={selected === 'us_us' ? style : null}
          >
            <BsPersonFill /> Usuario
          </label>
          <input
            type="text"
            name="us_us"
            value={datosForm.us_us}
            onFocus={() => setSelected('us_us')}
            onBlur={() => setSelected('')}
            onChange={datosDelFormulario}
            autoComplete="off"
          ></input>
        </div>
        <div className="iniciarSesion__container-form-contraseña">
          <label
            htmlFor="pw_us"
            className={datosForm.pw_us ? 'focused' : ''}
            style={selected === 'pw_us' ? style : null}
          >
            <FaLock /> Contraseña
          </label>
          <input
            type={showContra === false ? 'password' : 'text'}
            name="pw_us"
            value={datosForm.pw_us}
            onFocus={() => setSelected('pw_us')}
            onBlur={() => setSelected('')}
            onChange={datosDelFormulario}
            autoComplete="off"
          ></input>
          <span
            className="iniciarSesion__container-form-contraseña-mostrar"
            onClick={() =>
              showContra === false ? setShowContra(true) : setShowContra(false)
            }
          >
            {!showContra ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
        <button
          type="submit"
          className="iniciarSesion__container-form-button"
          form="loginForm"
          disabled={loading}
        >
          {loading ? (
            <div className="lds-ellipsisg">
              <div style={{ backgroundColor: 'white' }}></div>
              <div style={{ backgroundColor: 'white' }}></div>
              <div style={{ backgroundColor: 'white' }}></div>
              <div style={{ backgroundColor: 'white' }}></div>
            </div>
          ) : (
            'INICIAR SESIÓN'
          )}
        </button>
      </form>
      <Link to={'/recuperacontrasena'}>
        ¿Olvidaste tu usuario u contraseña?
      </Link>
      <Link to={'/login/2'}>¿Eres nuevo aquí?. Obt&eacute;n tu cuenta</Link>
    </div>
  );
};

export default IniciarHero;
