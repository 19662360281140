import React, { useState, useContext, useEffect } from 'react';
import imgTicket from '../../../../styles/images/ticket.png';
import consultarApi from '../../../../helpers/consultarApi';
import { TokenContext } from '../../../../context/TokenContext';
import { IoIosCheckmark } from 'react-icons/io';
const CuponDes = ({
  setMostrarErrores,
  setNavbarErrores,
  estaAplicado,
  setEstaAplicado,
}) => {
  const { setTokenCambiar, setTokenVencido, setToken, cancelar } =
    useContext(TokenContext);
  const [mostrar, setMostrar] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [valorCupon, setValorCupon] = useState('');
  const plan = JSON.parse(localStorage.getItem('RCV_DIGITAL_PLAN'));
  const preguntaCupon = JSON.parse(localStorage.getItem('RCV_DIGITAL_CUPON'));

  const [cupon, setCupon] = useState(null);
  const [cuponAplicado, setCuponAplicado] = useState(false);

  useEffect(() => {
    if (preguntaCupon) {
      setCupon([preguntaCupon]);
      setValorCupon(preguntaCupon.valorCupon);
      setCuponAplicado(true);
      setEstaAplicado('cupon');
    }
  }, []);

  const obtenerDescuento = async () => {
    const urlCupon = `v1/getCupon?p1=0&p2=${valorCupon}&p3=${plan?.id_pq}&p4=&op=1&token=`;
    const respuesta = await consultarApi(
      urlCupon,
      0,
      setCupon,
      setTokenCambiar,
      setTokenVencido,
      setToken,
      cancelar
    );

    if (respuesta) {
      setNavbarErrores([respuesta.errorData.message]);
      setMostrarErrores(true);
    } else {
      setTimeout(() => {
        setCupon(prevCupon => {
          if (prevCupon && prevCupon.length > 0) {
            setEstaAplicado('cupon');
            prevCupon[0].valorCupon = valorCupon;
            setCuponAplicado(true);
            localStorage.setItem(
              'RCV_DIGITAL_CUPON',
              JSON.stringify(...prevCupon)
            );
          } else {
            console.error('No se encontró el cupón o está vacío');
          }
          return prevCupon;
        });
      }, 1000);
    }
  };

  const quitarDescuento = () => {
    setCuponAplicado(false);
    setValorCupon('');
    setMostrar(false);
    localStorage.removeItem('RCV_DIGITAL_CUPON');
    setEstaAplicado('');
  };

  useEffect(() => {
    let codigoDescuentoLink = localStorage.getItem('RCV_DIGITAL_CODIGO_CUPON');

    if (codigoDescuentoLink) {
      const codigoParseado = JSON.parse(codigoDescuentoLink);
      const urlCupon = `v1/getCupon?p1=0&p2=${codigoParseado.codigoDescuento}&p3=${plan?.id_pq}&p4=&op=1&token=`;
      consultarApi(
        urlCupon,
        0,
        setCupon,
        setTokenCambiar,
        setTokenVencido,
        setToken,
        cancelar
      ).finally(() => localStorage.setItem('RCV_DIGITAL_DESCONTADO', true));

      setTimeout(() => {
        setCupon(prevCupon => {
          if (prevCupon && prevCupon.length > 0) {
            setEstaAplicado('cupon');
            prevCupon[0].valorCupon = valorCupon;
            setCuponAplicado(true);
            localStorage.setItem(
              'RCV_DIGITAL_CUPON',
              JSON.stringify(...prevCupon)
            );
            localStorage.removeItem('RCV_DIGITAL_CODIGO_CUPON');
          } else {
            console.error('No se encontró el cupón o está vacío');
          }
          return prevCupon;
        });
      }, 1000);
    }
  }, []);

  return (
    <div className="cuponDes">
      {cuponAplicado ? (
        <div className="cuponDes__aplicado">
          <div className="cuponDes__aplicado-header">
            <h2>{cupon[0].ds_cu}</h2>
            <span>
              Descuento REF:{cupon[0]?.mt_re_cu?.toFixed(2)} <IoIosCheckmark />
            </span>
          </div>

          <button onClick={quitarDescuento}>Quitar descuento</button>
        </div>
      ) : (
        <>
          <div
            className={`cuponDes__pregunta ${mostrar ? '' : 'active'} ${
              estaAplicado === 'agente' ? 'desactivado' : ''
            }`}
            onClick={() => setMostrar(true)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <span>
              ¿Tienes cup&oacute;n?<span>Haz click aqui</span>
            </span>
            <img
              src={imgTicket}
              alt="imagen de un cupon"
              className={`${isHovered ? 'hovered' : ''}`}
            />
          </div>
          <div className={`cuponDes__aplicacion ${mostrar ? '' : 'active'}`}>
            <div className="cuponDes__aplicacion-conteninput">
              <label>C&oacute;digo del cup&oacute;n</label>
              <input
                type="text"
                onChange={e => setValorCupon(e.target.value)}
              ></input>
            </div>

            <div className="cuponDes__aplicacion-contenbutton">
              <button className="cancelar" onClick={() => setMostrar(false)}>
                Cancelar
              </button>
              <button className="aplicar" onClick={() => obtenerDescuento()}>
                Aplicar
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CuponDes;
