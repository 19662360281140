import { IoIosCheckmark } from 'react-icons/io';
import { useEffect, useState } from 'react';

const DescuentoAplicado = ({
  descuentoJSON,
  setTotalBs,
  setTotalDolares,
  setCodigoAplicado,
  setDescuento,
  setShowCodigo,
  descuento,
  setEstaAplicado,
}) => {
  let plan = JSON.parse(localStorage.getItem('RCV_DIGITAL_PLAN'));
  let productos = JSON.parse(localStorage.getItem('RCV_DIGITAL_CARRITO'));
  const [descuentoDeshabilitado, setDescuentoDeshabiltiado] = useState(false);
  let total = 0;
  useEffect(() => {
    const descuentoEncontrado = localStorage.getItem('RCV_DIGITAL_DESCONTADO');
    if (descuentoEncontrado) {
      const descuentoEncontradoParseado = JSON.parse(descuentoEncontrado);
      setDescuentoDeshabiltiado(descuentoEncontradoParseado);
    }
  }, [descuento]);
  productos?.forEach(producto => {
    total += producto.precio * producto.cantidad;
  });
  const quitarDescuento = () => {
    localStorage.removeItem('RCV_DIGITAL_DESCUENTO');
    localStorage.removeItem('RCV_DIGITAL_USDESCUENTO');
    setTotalDolares(total + plan.mt_pr);
    let totalDolares = total + plan.mt_pr;
    setTotalBs(totalDolares * (plan.ta_fa / 100));
    setCodigoAplicado(false);
    setDescuento(null);
    setShowCodigo(false);
    setEstaAplicado('');
  };
  return (
    <div className="descuentoYPromo__descuento-descuentoAplicado">
      <div className="descuentoYPromo__descuento-descuentoAplicado-items">
        <span className="descuentoYPromo__descuento-descuentoAplicado-items-descuento">
          {descuentoJSON?.nb_us} {descuentoJSON?.ap_us}
        </span>
        <span className="descuentoYPromo__descuento-descuentoAplicado-items-monto"></span>

        <span className="descuentoYPromo__descuento-descuentoAplicado-items-aplicado">
          Descuento REF : {Number(descuentoJSON?.mt_ba).toFixed(2)}{' '}
          <IoIosCheckmark />
        </span>
      </div>
      {descuentoDeshabilitado ? null : (
        <button
          onClick={() => quitarDescuento()}
          className="descuentoYPromo__descuento-descuentoAplicado-button"
        >
          Quitar descuento
        </button>
      )}
    </div>
  );
};

export default DescuentoAplicado;
