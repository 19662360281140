import axios from '../api/axios';
import actualizarTokenNoLog from './actualizarTokenNoLog';

export const postContrato = async (
  referencia,
  setPagoStatus,
  bancoId,
  setTokenCambiar,
  setTokenVencido,
  setToken,
  setLoading
) => {
  let token = JSON.parse(localStorage.getItem('RCV_DIGITAL_TOKEN'));
  let idClient = JSON.parse(localStorage.getItem('CLIENT_ID'));
  let carrito = JSON.parse(localStorage.getItem('RCV_DIGITAL_CARRITO'));
  let idClientPropietario = JSON.parse(localStorage.getItem('PROPIETARIO_ID'));
  let idVehiculo = JSON.parse(localStorage.getItem('VEHICULO_ID'));
  let plan = JSON.parse(localStorage.getItem('RCV_DIGITAL_PLAN'));
  let cupon = JSON.parse(localStorage.getItem('RCV_DIGITAL_CUPON'));
  let idUsuDescuento = JSON.parse(
    localStorage.getItem('RCV_DIGITAL_USDESCUENTO')
  );
  let login = JSON.parse(localStorage.getItem('RCV_DIGITAL_LOGIN'));

  let proArray = [];

  if (carrito !== undefined) {
    carrito?.forEach(item => {
      proArray.push({
        id_pro: item.id_pro,
        ct_pro: item.cantidad,
      });
    });
  }

  const json = JSON.stringify({
    contrato: {
      id_cl: idClient,
      id_cl_pr: idClientPropietario === null ? idClient : idClientPropietario,
      id_vh: idVehiculo,
      id_pq: plan.id_pq,
      id_pr: plan.id_pr,
      id_fp: bancoId,
      dt_tp_pg: referencia,
      id_us_as: idUsuDescuento === undefined ? null : idUsuDescuento,
      cd_prom: cupon === undefined ? '' : cupon?.valorCupon,
      pro: carrito === undefined ? null : proArray,
    },
    trace: '0',
    token: token,
    ori: 'web',
  });

  const handleResponse = async data => {
    localStorage.setItem('RCV_DIGITAL_TOKEN', JSON.stringify(data.data.token));
    setPagoStatus({
      code: 1,
      message: 'Exitoso',
    });
  };

  const handleError = async error => {
    if (error.response && error.response.status === 403) {
      if (login === undefined || login === null) {
        console.log('actualizo sin log');
        actualizarTokenNoLog(setToken);
      } else {
        if (error.errorData) {
          setTokenVencido(error.errorData.token);
        }
        setLoading(0);
        setTokenCambiar(true);
        setTokenVencido(error.errorData.token);
        setPagoStatus({
          code: 2,
          message: error.response.data.message,
        });
      }
    }
  };

  try {
    const data = await axios.post(process.env.REACT_APP_CONTRATOS, json);
    handleResponse(data);
  } catch (error) {
    handleError(error);
    console.log(error);
  }
};
